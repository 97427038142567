import {
    type ChallengeException,
    type ChallengeInterface,
    ErrorSeverity,
    ExceptionIdentifier,
    type Stage,
    type StageError,
    StageType,
} from "../domain";

/**
 * Match stage type guard.
 *
 * @param payload A stage (error) object.
 * @param type The type of stage we want to check.
 * @returns Whether payload matches the type of stage.
 */
export function matchStageType<T extends Stage>(payload?: Stage | StageError, type?: T["type"]): payload is T {
    if (!payload || !("type" in payload)) return false;

    return payload.type === type;
}

/**
 * Match stage type guard.
 *
 * @param payload A stage (error) object.
 * @param identifier The stage identifier we want to check.
 * @returns Whether payload matches the type of stage.
 */
export function matchStageIdentifier<T extends ChallengeInterface>(payload?: Stage | StageError, identifier?: T["identifier"] | Array<T["identifier"]>): payload is T {
    if (!payload || !("type" in payload) || payload.type !== StageType.CHALLENGE || !identifier) return false;
    if (Array.isArray(identifier)) return identifier.includes(payload.identifier);

    return payload.identifier === identifier;
}

/**
 * Determine Stage exception severity.
 *
 * @param id The current stage exception identifier.
 * @returns The severity of the stage error.
 */
export function getExceptionSeverity(id: ExceptionIdentifier): ErrorSeverity {
    switch (id) {
        // Treat as informational.
        case ExceptionIdentifier.INVALID_CREDENTIALS:
        case ExceptionIdentifier.AUTHENTICATION_REQUIRED:
        case ExceptionIdentifier.CONFIRMATION:
        case ExceptionIdentifier.PASSWORD_RESET_REQUIRED:
        case ExceptionIdentifier.UNSAFE_PASSWORD_RESET_REQUIRED:
        case ExceptionIdentifier.CAPTCHA_REQUIRED:
            return ErrorSeverity.INFO;

        // Treat as warning.
        case ExceptionIdentifier.ABORT_ERROR:
        case ExceptionIdentifier.INVALID_INPUT:
        case ExceptionIdentifier.CAPTCHA_INVALID:
        case ExceptionIdentifier.HTTP_429:
        case ExceptionIdentifier.INCONSISTENT_DATA:
        case ExceptionIdentifier.EXPIRED:
        case ExceptionIdentifier.ACCOUNT_BLOCKED:
            return ErrorSeverity.WARNING;

        // Treat as error.
        case ExceptionIdentifier.INVALID_STATE:
        case ExceptionIdentifier.HTTP_409:
        case ExceptionIdentifier.HTTP_422:
        case ExceptionIdentifier.HTTP_500:
        case ExceptionIdentifier.HTTP_502:
        case ExceptionIdentifier.HTTP_504:
        case ExceptionIdentifier.NETWORK_ERROR:
        case ExceptionIdentifier.JSON_ERROR:
            return ErrorSeverity.ERROR;

        // Treat as fatal/unrecoverable.
        case ExceptionIdentifier.NO_INTERACTION_ID:
        case ExceptionIdentifier.INTERACTION_UNKNOWN:
        case ExceptionIdentifier.UNKNOWN:
        default:
            return ErrorSeverity.FATAL;
    }
}

/**
 * Get stage error severity.
 *
 * @param payload The current active stage.
 * @returns The severity of the stage error; treats missing stage as critical.
 */
export function getStageErrorSeverity(payload?: Stage | StageError): ErrorSeverity {
    // Treat missing stage as critical
    if (!payload) return ErrorSeverity.FATAL;

    // Regular stage with (optional) ChallengeExceptions in it.
    if ("type" in payload) {
        // Try and extract the list of exceptions
        const exceptions = ("exceptions" in payload && payload.exceptions) || [] as ChallengeException[];

        // Pick the highest severity from the list
        return exceptions.reduce<ErrorSeverity>((prev, current) => {
            // Determine severity from identifier.
            const severity = getExceptionSeverity(current.id);
            if (severity > prev) return severity;

            return prev;
        }, ErrorSeverity.NONE);
    }

    // StageError with severity provided.
    if (payload.severity) return payload.severity;

    // Determine severity from identifier.
    return getExceptionSeverity(payload.id);
}

/**
 * Get the highest challenge exception
 *
 * @param exceptions The list of exceptions
 * @returns the exception with the highest severity or undefined if no errors.
 */
export function getHighestSeverityChallengeException(
    exceptions?: ChallengeException[],
): ChallengeException | undefined {
    if (!exceptions?.length) return undefined;

    let maxSeverity = ErrorSeverity.NONE;
    let maxException: ChallengeException | undefined;

    // for (let n = 0; n < exceptions.length; ++n) {
    for (const exception of exceptions) {
        const newSeverity = getExceptionSeverity(exception.id);
        if (newSeverity === ErrorSeverity.FATAL) return exception;

        if (newSeverity > maxSeverity) {
            maxSeverity = newSeverity;
            maxException = exception;
        }
    }
    return maxException;
}

/**
 * Extract errors from Stage (error) object.
 *
 * @param stage The current Stage or StageError state.
 * @returns A list of errors, if any.
 */
export function splitStageErrors<T extends Stage>(stage?: T | StageError): { stage?: T; stageErrors: StageError[] } {
    const stageErrors: StageError[] = [];

    // No stage object
    if (!stage) return { stage: undefined, stageErrors };

    // StageError
    if (!("type" in stage)) {
        return { stage: undefined, stageErrors: [stage] };
    }

    // Translate Stage exceptions into StageError object
    return {
        stage,
        stageErrors: ("exceptions" in stage && stage.exceptions?.map((e) => ({ id: e.id, message: e.detail }))) || [],
    };
}
