/* v8 ignore start */
/* eslint-disable jsdoc/require-jsdoc */
// @ts-expect-error there are no TS definitions for this package
import { KROT } from "captcha-eu-sdk";
import { Match, Suspense, Switch } from "solid-js";
import { render } from "solid-js/web";
import App from "./App";
import { Loader } from "./components/Loader";
import { config } from "./config";
import { translationsLoaded } from "./config/locale";
import { ThemeProvider } from "./styles";
import "./utilities/polyfill/polyfills";

if (config.captchaPublicKey) {
    KROT.init();
    KROT.setup(config.captchaPublicKey);
    if (config.captchaProxyUrl) {
        KROT.KROT_HOST = config.captchaProxyUrl;
    }
}

const container = document.querySelector("#sso-root");

if (!(container instanceof HTMLElement)) {
    throw new Error(
        "Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?",
    );
}

async function enableMSW(): Promise<void> {
    if (!config.debug.mockResponse) return;

    const { worker } = await import("../tests/mockserver/msw-server");
    await worker.start({ onUnhandledRequest: "bypass" });

    // set some interaction ID for testing
    sessionStorage.setItem("interactionId", "MOCK_ID");
}

void enableMSW().then(() => render(
    () => (
        <ThemeProvider>
            <Suspense fallback={<Loader dialog noFallbackTitle delay/>}>
                <Switch fallback={<Loader dialog noFallbackTitle delay/>}>
                    <Match when={translationsLoaded()}>
                        <App/>
                    </Match>
                </Switch>
            </Suspense>
        </ThemeProvider>
    ),
    container,
));
