import { Popover, Typography, useTheme } from "@suid/material";
import { type ParentComponent, createSignal } from "solid-js";

export const Tooltip: ParentComponent<{ title: string }> = (props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    let el!: HTMLElement;

    const handlePopoverOpen = (event: { currentTarget: Element }): void => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (): void => {
        setAnchorEl(null);
    };

    const open = (): boolean => !!anchorEl();

    return (
        <>
            <Typography
                as={"span"}
                ref={el}
                aria-owns={open() ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ overflow: "visible !important" }}
            >
                {props.children}
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{ ...theme.mixins.tooltip, pointerEvents: "none" }}
                open={open()}
                anchorEl={anchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                container={el}

            >
                <Typography sx={{ p: 1 }}>{props.title}</Typography>
            </Popover>
        </>
    );
};
