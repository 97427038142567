import { useTheme, Checkbox } from "@suid/material";
import { type Component, createSignal } from "solid-js";
import { UAParser } from "ua-parser-js";
import { Button } from "../components/Button";
import { LoginDialog } from "../components/LoginDialog";

const uaParser = new UAParser();
const userAgent = uaParser.getResult();

export const Test: Component<{ bool?: boolean }> = () => {
    const theme = useTheme();

    const [fullWidth, setFullWidth] = createSignal(false);
    const [fullHeight, setFullHeight] = createSignal(false);
    const [minContentWidth, setMinContentWidth] = createSignal(false);
    const [minContentHeight, setMinContentHeight] = createSignal(false);
    const [fullScreen, setFullScreen] = createSignal(false);
    const [horizontal, setHorizontal] = createSignal(false);

    return (
        <div>
            <LoginDialog
                id="AccountComplete"
                title={"TITLE"}
                description="description"
                link="link"
                fullWidth={fullWidth()}
                minContentWidth={minContentWidth()}
                fullHeight={fullHeight()}
                minContentHeight={minContentHeight()}
                fullscreen={fullScreen()}
                orientation={horizontal() ? "horizontal" : "vertical"}
                primaryButton={
                    <Button
                        data-testid="SubmitButton"
                        sx={theme.mixins.button}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={true}
                        onClick={() => {}}
                    >
                        SUBMIT
                    </Button>
                }
                errors={[]}
            >
                <div><b>UserAgent:</b> {userAgent.ua}</div>
                <div><b>Browser name:</b> {userAgent.browser.name}</div>
                <div><b>Browser version:</b> {userAgent.browser.version}</div>
                <div><b>OS name:</b> {userAgent.os.name}</div>
                <div><b>OS version:</b> {userAgent.os.version}</div>
                <div><b>Device:</b> {userAgent.device.model} {userAgent.device.type} {userAgent.device.vendor}</div>
                <Checkbox checked={fullWidth()} onChange={() => setFullWidth((o) => !o)}>FullWidth</Checkbox>
                <Checkbox checked={fullHeight()} onChange={() => setFullHeight((o) => !o)}>FullHeight</Checkbox>
                <Checkbox checked={minContentWidth()} onChange={() => setMinContentWidth((o) => !o)}>
                    MinContentWidth
                </Checkbox>
                <Checkbox checked={minContentHeight()} onChange={() => setMinContentHeight((o) => !o)}>
                    MinContentHeight
                </Checkbox>
                <Checkbox checked={fullScreen()} onChange={() => setFullScreen((o) => !o)}>FullScreen</Checkbox>
                <Checkbox checked={horizontal()} onChange={() => setHorizontal((o) => !o)}>Horizontal</Checkbox>
            </LoginDialog>
        </div>
    );
};
