import { type DialogTypeMap } from "@suid/material/Dialog";
import { type JSX, lazy } from "solid-js";
import { type StageIdentifier } from "../../domain";
import { type CloseReason } from "../PdfModalDialog";

const QrScannerDialog = lazy(async () => import("./QrScannerDialog"));

/** Modal dialog properties. */
export type QrScannerDialogProps = DialogTypeMap["props"] & {
    /** Header title component. */
    title?: JSX.Element;

    /** The QR scan type we want to facilitate. */
    qrType?: "device" | "authenticate";

    /** On close event. */
    onClose?: ((event: Event, reason: CloseReason) => void);

    /** Whether to show the accept and reject buttons. */
    showButtons?: boolean;

    /** Authenticate accompanying stage identifier */
    authenticateStageIdentifier?: StageIdentifier;
};

export { QrScannerDialog };

export default QrScannerDialog;
